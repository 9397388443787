:root {
  //COLORS
  --color-primary: #f5cc16;
  --color-primary-2: #c4a108;
  --color-primary-3: #ffc444;
  --color-primary-4: #ffcc60;
  --color-primary-5: #f5ead2;
  

  // STATUS
  --color-red: #e12f2f;
  --color-red-2: #9f0000;
  --color-red-3: #f7d1cd;
  
  --color-yellow: #e7d788;
  --color-yellow-2: #ffaa00;
  --color-yellow-3: #8f4f00;
  
  --color-green: #569755;
  --color-green-2: #a3f868;
  --color-green-3: #d3eace;

  // NEUTRALS

  --color-dark: #0c090d;
  --color-dark-2: #312d33;

  --color-white: #ffff;
  --color-white-2: #f6f8fb;
  --color-white-3: #f4f9ff;

  --color-grey: #b3b3c5;
  --color-grey-2: #d9d9dc;
  --color-grey-3: #e0e9f3;
  --color-grey-4: #d1d1d1;
  --color-grey-5: #ebebf5;

  // PALLET
  --color-text: var(--color-dark);
  --color-background: var(--color-white);
  --color-link: var(--color-primary);
  --color-caption: var(--color-dark);
  --color-button: var(--color-primary);
  --color-heading: var(--color-dark);

  // FONT SIZES
  --default-font-size: 1.8rem;
  --default-font-h1: 6.4rem;
  --default-font-h2: 4rem;
  --default-font-h3: 3.2rem;
  --default-font-subtitle: 2.4rem;
  --default-font-small: 1.4rem;
  --default-font-xsmall: 1.2rem;

  //FONT
  --primary-font: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Montserrat', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  --secondary-font: 'Roboto Slab', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --tertiary-font: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

// FONT
$default-font-size: 1.8rem;
$default-font-subtitle: 2.4rem;
$default-font-small: 1.4rem;

// SHADOWS
$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
