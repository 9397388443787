@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '../assets/styles/utilities/breakpoints';
@import '../assets/styles/utilities/variables';

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  /* 1rem = 10px the html fontsize determines the value of one rem */

  @media only screen and (max-width: $bp-1300) {
    font-size: 56.25%; //1rem becomes 9px.
  }

  @media only screen and (max-width: $bp-1200) {
    font-size: 50%; //1rem becomes 8px.
  }

  @media only screen and (max-width: $bp-360) {
    font-size: 37.5%; //1rem becomes 6px.
  }
  scroll-behavior: smooth;
}

body {
  background-color: var(--color-background);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: var(--default-font-size);
  line-height: 2.2rem;
  color: var(--color-text);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;

  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}


.mimaYScroll {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 1rem 1rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 1rem;
  }
}

label {
  font-size: 1.4rem;
  font-family: inherit;
  // padding-left: 2rem;
  box-sizing: border-box;
  width: 38rem;
}

.modal {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;

  &__beside {
    display: flex;
    width: 38rem;
    justify-content: space-between;

    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 38rem;
      gap: 2rem;
    }
  }
}



.border {
  &__v {
    content: '';
    border-right: 1px solid var(--color-grey-6);
    margin: 0 4rem 0 2rem;
  }
}

.loaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 3rem;
}

.whiteTable {
  width: 100%;
  margin-top: 3rem;
  font-size: var(--default-font-small);
  overflow-x: scroll;
  // width: 100%;
  // max-height: 100vh;
  // margin-top: 3rem;
  // font-size: 1.2rem;
  // overflow-y: auto;
  // overflow-x: auto;

  &::-webkit-scrollbar:horizontal {
    width: 0;
    height: 0.7rem;
    background: var(--color-white);
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background: var(--color-grey);
    border-radius: 2rem;
  }

  &::-webkit-scrollbar {
    width: 1rem;
    background-color: var(--color-white);
    border-radius: 0 3rem 3rem 0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-grey);
    border-radius: 3rem;
  }

  table {
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    max-width: 100%;
    border: none;
  }

  tr {
    border-bottom: 2px solid var(--color-grey-4);

    & > :first-child {
      padding-left: 2rem;
    }

    & > :last-child {
      padding-right: 2rem;
    }

    & > :not(:first-child) {
      padding-left: 1rem;
    }
  }

  td,
  th {
    padding: 2rem 0;
  }

  th {
    font-weight: 700;
    position: relative;
    cursor: pointer;

    span {
      position: absolute;
      top: 2.25rem;
      margin-left: 1rem;
    }
  }

  thead {
    tr {
      position: sticky;
      top: 0;
    }
  }
}

.searchInMiddle {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 2rem;
}

ul.custom-list li {
  display: flex;
  list-style-type: none;
  margin: 1rem;
  font-size: 14px;
  color: var(--color-dark);
}

ul.custom-list li:before {
  content: '✓';
  margin-right: 0.5em;
  font-weight: 700;
  color: var(--color-fash-primary-2);
}

.tableStatus {
  display: flex;
  // font-weight: 700;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 85%;
  font-size: 1rem;
  padding: 1.2rem 2rem;

  &__red {
    background-color: var(--color-error);
    color: var(--color-white);
  }

  &__green {
    background-color: var(--color-green);
    color: var(--color-white);
  }

  &__yellow {
    background-color: var(--color-yellow);
    color: var(--color-dark);
  }

  &__dark {
    background-color: var(--color-dark);
    color: var(--color-white);
  }
}

.tempDiv {
  display: flex;
  margin: 7rem 5rem;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media only screen and (max-width: $bp-800) {
    flex-direction: column;
    margin: 7rem 0;
  }

  @media only screen and (max-width: $bp-500) {
    margin: 2rem 0;
  }

  & > :last-child {
    padding-right: 5rem;
    width: 60rem;

    @media only screen and (max-width: $bp-800) {
      width: auto;
      padding-right: 0;
    }
  }

  &__img {
    width: 40rem;
  }
}

.tableAction {
  &__text {
    font-weight: 700;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}

// h1 {
//   font-family: var(--secondary-font);
//   font-size: 6.4rem;
//   font-weight: 400;
//   line-height: normal;
//   text-transform: capitalize;

//   @media only screen and (max-width: $bp-600) {
//     font-size: 3.2rem;
//     text-align: center;
//   }
// }